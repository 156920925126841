/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/04/2022.
 */

// console.log("Hello Jason!");

import { dceDebug } from "./helpers/debug";
import {
  onDocumentLoad,
  getEmbeddableSource,
  getEmbeddableScriptDom,
  postMessageToWixIframes,
  onLocationChange,
  attachScript,
  filterInitializedWidgets,
} from "./helpers/launcher";
import { WT } from "./apps/admin/consts/widget";
import appConfig from "./appConfig";
import CalendarPreloader from "./apps/calendar/preloader";
const KIND = "loader";
const backendAddress = getEmbeddableSource();
const scriptTag = getEmbeddableScriptDom();

export const postCalendarLoader = (widgetName) => {
  dceDebug("postCalendarLoader: ", widgetName);
  const kind = `${widgetName}Loader`;
  const widgetSelector = ".dce-calendar, .ccb-events";
  window.__DCE[kind] = {
    init: false,
    initializedWidgets: [],
  };
  const widgetObjects = document.querySelectorAll(widgetSelector);
  if (!widgetObjects.length) {
    console.warn(
      `${appConfig.displayName}: No ${widgetName} widget objects present on this page`
    );
  } else {
    for (let i = 0; i < widgetObjects.length; i++) {
      const widgetAttributes = widgetObjects[i].attributes;
      const isIframe =
        widgetAttributes.iframe && widgetAttributes.iframe.value === "true";

      if (isIframe) {
        console.log(
          "skipping post calendar loader due to iframe or wix",
          widgetObjects[i].id
        );
        continue;
      }
      const slug = isIframe ? widgetAttributes.slug.value : null;

      let singleItemSlug = widgetAttributes.singleitem
        ? widgetAttributes.singleitem.value
        : null;

      if (widgetAttributes.singleeventslug) {
        // fallback to v2
        singleItemSlug = widgetAttributes.singleeventslug.value;
      }

      // console.log("runWidget", kind, widgetObjects[i].id, isIframe);
      try {
        const domID = document.getElementById(widgetObjects[i].id);

        if (domID.children.length) {
          console.warn("Widget already loaded", widgetObjects[i].id);
          continue;
        }

        const loaderContainerId = `loader--${domID.id}`;
        const loaderDom = document.createElement("div");
        loaderDom.className = "rev-preloader--container";
        loaderDom.id = loaderContainerId;
        loaderDom.dataset.wt = domID.dataset.wt;

        domID.parentNode.insertBefore(loaderDom, domID.nextSibling);
        CalendarPreloader({
          loaderContainerId,
          slug,
          singleItemSlug,
          widgetUUID: domID.id,
        });

        window.__DCE[kind].init = false;
      } catch (e) {
        console.error(
          "Loading preloader widget" + widgetObjects[i].id + " failed"
        );
        console.error(e);
      }
    }
  }
};

const loadWidget = (widgetName) => {
  const widgetFileName = `${widgetName}${window.__DCE__DEV__ ? "" : ".min"}.js`;
  const widgetUri = `${backendAddress}/widgets/${widgetFileName}`;

  dceDebug("loading widget: ", widgetName, widgetUri);

  if (widgetName === WT.calendar) {
    postCalendarLoader(widgetName);
  }

  attachScript(widgetUri);
};

/**
 *
 * @param kind
 * @param widgetId
 */
const scrollToWidget = (kind, widgetId) => {
  // scroll to this div if query param contains its uuid
  if (window.location.search.includes(`${kind}=${widgetId}`)) {
    const element = document.getElementById(widgetId);
    if (element) {
      element.scrollIntoView();
    }
  }
};

const initializeWidgets = () => {
  dceDebug("initializeWidgets");

  // .ccb-events is for backward compatibility, used by all calendars embedded before v3.
  const embeddableDoms = Array.from(
    document.querySelectorAll(
      ".dce-calendar, .dce-groups, .ccb-events, .dce-signup"
    )
  )
    // do not initialize widgets that are initialized
    .filter(filterInitializedWidgets);

  if (!embeddableDoms.length && !scriptTag && !scriptTag?.getAttribute("wix")) {
    setTimeout(initializeWidgets, 500);
    return;
  }

  const loadedWidgets = [];
  if (
    !scriptTag ||
    !scriptTag.getAttribute("wix") ||
    (scriptTag.getAttribute("wix") === "true" &&
      scriptTag.getAttribute("wixbuilder") === "true")
  ) {
    for (const dom of embeddableDoms) {
      dom.classList.forEach((value, key) => {
        // check for backward compatibility of ccb-events classname
        if (value.toLowerCase() === "ccb-events") {
          if (!loadedWidgets.includes(WT.calendar)) {
            loadedWidgets.push(WT.calendar);
            loadWidget(WT.calendar);
            scrollToWidget(WT.calendar, dom.id);
          }
          return;
        }

        const widget = value.replace("dce-", "");
        dceDebug("widget", widget);
        if (!loadedWidgets.includes(widget)) {
          loadedWidgets.push(widget);
          loadWidget(widget);
          scrollToWidget(widget, dom.id);
        }
      });
    }
  } else {
    window.addEventListener("message", (event) => {
      // builder mode
      if (
        event.data.type &&
        !loadedWidgets.includes(event.data.type) &&
        event.data.builder
      ) {
        // console.log("builder mode message", event);
        loadedWidgets.push(event.data.type);
        // console.log("builder wix load widget");
        loadWidget(event.data.type);
      }

      // production mode
      if (event.data.type && !loadedWidgets.includes(event.data.type)) {
        const iframes = document.getElementsByTagName("IFRAME");
        let target = null;

        // console.log("iframes", iframes);
        for (let i = 0, iframe, win; i < iframes.length; i++) {
          iframe = iframes[i];

          // Cross-browser way to get iframe's window object
          win = iframe.contentWindow || iframe.contentDocument.defaultView;

          if (win === event.source) {
            target = iframe;
            break;
          }
        }

        if (target) {
          const wixFrame = target.closest("wix-iframe");
          wixFrame.innerHTML = `<div class="dce-${event.data.type}" id="${event.data.widgetId}"/>`;
          wixFrame.closest("div").style.height = "auto";
        }

        loadedWidgets.push(event.data.type);
        loadWidget(event.data.type);
      }
    });

    const sendAdvertisementToWix = () => {
      postMessageToWixIframes((iframe, i) => {
        // console.log(iframe);
        iframe.contentWindow.postMessage({ advertise: true }, "*");
      });
    };

    const interval = setInterval(() => {
      if (loadedWidgets.length) {
        clearInterval(interval);
        return;
      }
      sendAdvertisementToWix();
    }, 300);
  }
};

// const reinitializeWidgets = (type, uuid) => {
//   dceDebug("reinitializeWidgets", type, uuid);
//   runWidgetInitialization(type, `div[id='${uuid}']`);
// };

(() => {
  window.__DCE = window.__DCE || {};

  const ID = new Date() * 1;

  dceDebug("script ID", ID, window.DCELOADERID);

  if (window.DCELOADERID) {
    return;
  } else {
    window.DCELOADERID = ID;
    window.__DCE[KIND] = {
      isWidgetLoaded: false,
    };
  }

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    window.__DCE__DEV__ = true;
  } else {
    window.__DCE__DEV__ = false;
  }

  onDocumentLoad("loader", initializeWidgets);
  onLocationChange(initializeWidgets);

  // window.addEventListener(
  //   "message",
  //   (event) => {
  //     dceDebug(
  //       "dce-embeddable",
  //       event.data,
  //       event.data.type === "dce-embeddable"
  //     );
  //     if (event.data.type === "dce-embeddable") {
  //       // console.log("received message to reinitializeWidgets");
  //       reinitializeWidgets(event.data.widgetType, event.data.widgetUUID);
  //     }
  //   },
  //   false
  // );
})();
